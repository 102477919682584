import * as React from 'react';
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';

type Props = {
  paymentDetail?: any,
  address?: any
}

export default function BasicCard(props: Props) {
  const { paymentDetail, address } = props;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {paymentDetail?.[0]?.metadata?.data?.object?.lines?.data[0]?.description}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          <LocationOnIcon></LocationOnIcon>{address?.address} {address?.number} {address?.city} {address?.neighborhood} - {address?.state} {address?.zip_code}
        </Typography>
        <Typography variant="body2">
          {paymentDetail?.[0]?.metadata?.data?.object?.footer}
        </Typography>
      </CardContent>
    </Card>
  );
};