import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CircleIcon from '@mui/icons-material/Circle';
import moment from 'moment';

type Props = {
  transactions: any[];
};

export default function DenseTable(props: Props) {
  const { transactions } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>DATA</TableCell>
            <TableCell>Pago</TableCell>
            <TableCell>PDF</TableCell>
            <TableCell>FATURA</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions?.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{moment(row?.created_at).format('DD/MM/yyyy')}</TableCell>
              <TableCell component="th" scope="row">
                {row?.metadata?.data?.object?.paid ? (<CircleIcon style={{ color: 'green' }}/>) : (<CircleIcon style={{ color: 'red' }}/>)}
              </TableCell>
              <TableCell component="th" scope="row">
                <a href={row?.metadata?.data?.object?.invoice_pdf}>
                  <PictureAsPdfIcon></PictureAsPdfIcon>
                </a>
              </TableCell>
              <TableCell component="th" scope="row">
                <a href={row?.metadata?.data?.object?.hosted_invoice_url}>
                  <PictureAsPdfIcon></PictureAsPdfIcon>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}