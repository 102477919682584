import axios from 'axios';

const API_URL = 'https://api.stokado.com.br/payments/list';

class UserService {
  getTransactions(
    token: string,
    reservedId: number,
  ) {
    return axios.post(API_URL, { reservedId }, { headers: { Authorization: token } });
  }
}

export default new UserService();
