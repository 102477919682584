import { Component } from "react";
import TablePayments from './table-payments';
import TablePaymentsSent from './table-payments-sent';
import PaymentDetail from './payment.detail';
import PaymentService from '../services/payment.service';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

type Props = {
  match: {
    params: {
      token: string,
      reservedId: string,
    }
  }
};

type State = {
  transactions?: {
    created?: any,
    paidSuccess?: any,
    sent?: any,
    address?: any,
  };
  load: boolean;
  error: boolean;
}

export default class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      transactions: {},
      load: true,
      error: false,
    };
  }

  async componentDidMount() {
    const { match: { params: { token, reservedId } } } = this.props;

    let error = false;

    const transactions = await PaymentService.getTransactions(
      token,
      +reservedId,
    )
    .catch(() => {
      error = true
      return {
        data: [],
      }
    });

    this.setState({
      transactions: transactions?.data,
      load: false,
      error,
    });
  }

  render() {
    return (
      <div>
        { this.state.error ? (<Alert severity="warning">Ops, encontramos um erro, caso o mesmo persista entre em contato conosco.</Alert>) : null }
        { !this.state?.transactions?.paidSuccess?.length && !this.state.load && !this.state.error ? (<Alert severity="info">Você não possui nenhuma fatura.</Alert>) : null }
        { this.state.load ? (<CircularProgress></CircularProgress>) : null }
        { this.state?.transactions?.created?.length && this.state?.transactions?.paidSuccess ? <PaymentDetail paymentDetail={this.state?.transactions?.created} address={this.state?.transactions?.address}></PaymentDetail> : null }<br/>
        <h6>Faturas pagas</h6>
        <TablePayments transactions={this.state?.transactions?.paidSuccess}></TablePayments><br/>
        <h6>Todas as faturas</h6>
        <TablePaymentsSent transactions={this.state?.transactions?.sent}></TablePaymentsSent>
      </div>
    );
  }
}
