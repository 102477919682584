import { Component } from "react";

type Props = {};

type State = {
  transactions: any[];
}

export default class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      transactions: []
    };
  }

  render() {
    return (
      <h1>Bem vindo a STOKADO</h1>
    );
  }
}
